
import { mapWritableState } from 'pinia'
import { useDcmStore } from '~/stores/dcm'
import { useUiStore } from '@/stores/ui'
import KeenSlider from 'keen-slider'

export default {
  name: 'MPLandingSlider',
  setup() {
    const uiStore = useUiStore()
    return { uiStore }
  },
  data() {
    return {
      sliderOptions: {
        created: this.sliderCreated,
        slides: {
          perView: 1,
        },
        animationEnded: s => {
          const idx = s.track.details.rel
          this.loaded[idx] = true
          this.$forceUpdate()
        },
        slideChanged: s => {
          this.current = s.track.details.rel
        },
        loop: true,
        initial: 0,
      },
      sliderPlugins: [
        slider => {
          let timeout
          let mouseOver = false
          function clearNextTimeout() {
            clearTimeout(timeout)
          }
          function nextTimeout() {
            clearTimeout(timeout)
            if (mouseOver) return
            timeout = setTimeout(() => {
              if (slider.slides.length > 0) {
                slider.next()
              }
            }, 3000)
          }

          slider.on('created', () => {
            slider.container.addEventListener('mouseover', () => {
              mouseOver = true
              clearNextTimeout()
            })
            slider.container.addEventListener('mouseout', () => {
              mouseOver = false
              nextTimeout()
            })
            nextTimeout()
          })
          slider.on('dragStarted', clearNextTimeout)
          slider.on('animationEnded', nextTimeout)
          slider.on('updated', nextTimeout)
        },
      ],
      loaded: [true],
      loader: true,
      slider: null,
      showAllSlid: false,
      current: 0,
      timerStartSlider: null,
      timerShowAllSlid: null,
    }
  },
  computed: {
    ...mapWritableState(useDcmStore, ['header_market_slider']),
    dotHelper() {
      return this.slider
        ? [...Array(this.slider?.track?.details?.slides?.length).keys()]
        : []
    },
    headerMarketSlider() {
      const lang = this.$i18n?.locale || 'az'
      let action_url =
        'https://umico.az/ru/categories/3-mobilnye-telefony-i-smartfony?q%5Bmanufacturer_in%5D=Samsung'
      let images = {
        1: '/images/mp-landing-banner-samsungSmartphones-ru.webp',
        2: '/images/mp-landing-banner-samsungSmartphones-ru-mob.webp',
        3: '/images/mp-landing-banner-samsungSmartphones-ru.webp',
        4: '/images/mp-landing-banner-samsungSmartphones-ru-mob.webp',
      }
      if (lang === 'az') {
        images = {
          1: '/images/mp-landing-banner-samsungSmartphones-az.webp',
          2: '/images/mp-landing-banner-samsungSmartphones-az-mob.webp',
          3: '/images/mp-landing-banner-samsungSmartphones-az.webp',
          4: '/images/mp-landing-banner-samsungSmartphones-az-mob.webp',
        }
        action_url =
          'https://umico.az/categories/3-mobil-telefonlar-ve-smartfonlar?q%5Bmanufacturer_in%5D=Samsung'
      }
      const header_market_slider = this.header_market_slider.items?.map(
        (slider, index) => {
          if (index > 1 && !this.showAllSlid) {
            return 'empty.webp'
          } else {
            return slider.images?.[1] || []
          }
        },
      )
      return header_market_slider
    },
    locale() {
      return this.$i18n.locale
    },
  },

  mounted() {
    if (this.slider) this.slider.destroy()
    this.timerStartSlider = setTimeout(() => {
      this.slider = new KeenSlider(
        this.$refs.slider,
        this.sliderOptions,
        this.sliderPlugins,
      )
    }, 100)
    this.timerShowAllSlid = setTimeout(() => {
      this.showAllSlid = true
    }, 2000)
  },

  beforeDestroy() {
    if (this.slider) this.slider.destroy()
    clearTimeout(this.timerShowAllSlid)
    clearTimeout(this.timerStartSlider)
  },
  methods: {
    handleBannerClick(idx) {
      const sliderData = this.getCurrentSliderData(idx)
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          event: 'select_promotion',
          creative_slot: 'homepage_carousell_banner',
          creative_url: sliderData.action_url || null,
          publish_date: sliderData.active_from || null,
          unpublish_date: sliderData.active_to || null,
          location_code: this.header_market_slider.location_code || null,
          object: 'banner',
          creative_id: sliderData?.id || null,
        })
      }

      if (sliderData?.is_clickable && sliderData?.action_url) {
        window.location.href = sliderData.action_url
      }
    },
    sliderCreated() {
      this.loader = false
    },
    getCurrentSliderData(index) {
      return this.header_market_slider?.items[index] || {}
    },
    setDataLayer(index) {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          event: 'main_slider_click',
          index: index + 1,
        })
      }
    },
  },
}
